.select2-container--default .select2-selection--single .select2-selection__rendered {
    width: unset;
}

.select2-container .select2-container--focus .select2-selection--multiple {
    border: 1px solid var(--#{$variable-prefix}primary);
}
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:focus, .select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover {
    border-right: 0px;
    color: var(--bs-white);
    background-color: var(--#{$variable-prefix}primary);
}
.select2-search__field {
    background: var(--bs-gray-900);
    border-color: var(--bs-border-color) !important;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice__display {
    color: var(--bs-white);
}
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
    color: var(--bs-white);
    height: 1.5rem;
    width: 1rem;
    border-right: 0px;
}
.select2-container--default .select2-results__option--highlighted.select2-results__option--selectable,
.select2-container--classic .select2-results__option--highlighted.select2-results__option--selectable {
    background-color: var(--#{$variable-prefix}primary);
    color: var(--bs-white);
}
.select2-container--classic .select2-selection--single .select2-selection__arrow{
    height: 30px;
}
.select2-container--classic.select2-container--open .select2-dropdown {
    border: 1px solid var(--#{$variable-prefix}secondary);
}
.select2-container--classic .select2-selection--single:focus {
    border:1px solid var(--#{$variable-prefix}primary)
}


//recent changes

.select2-container--default .select2-results__option--selected,
.select2-container--classic .select2-results__option--selected{
    background: var(--#{$variable-prefix}primary-bg-subtle);
    color: var(--#{$variable-prefix}primary);
}
.select2-container--default .select2-selection--single .select2-selection__arrow{
    right: 1rem;
}
.select2-container .select2-selection--single .select2-selection__rendered,
.select2-container--default .select2-selection--multiple{
    padding: $form-select-padding-y $form-select-indicator-padding $form-select-padding-y $form-select-padding-x;
    color: $input-color;
    line-height: inherit;
}
.select2-container--default .select2-selection--single,
.select2-container--default .select2-selection--multiple{
    color: $input-color;
    background-color: $input-bg;
    border-radius: $input-border-radius;
    min-height: 2.5rem;
    background-clip: padding-box;
    border: $input-border-width solid var(--bs-border-color);
}
.select2-container--default .select2-selection--single .select2-selection__arrow,
.select2-container--default .select2-selection--single .select2-selection__clear,
.select2-container--classic .select2-selection--single .select2-selection__arrow{
    height: 100%;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice {
    background-color: var(--#{$variable-prefix}primary);
    border: 1px solid var(--#{$variable-prefix}primary);
    margin: unset;
    margin-right: .5rem;
    margin-bottom: .5rem;
}
.select2-container--classic .select2-selection--single{
    min-height: 2.625rem;
}
.select2-container--default.select2-container--focus .select2-selection--single,
.select2-container--default.select2-container--focus .select2-selection--multiple,
.select2-container--classic.select2-container--open .select2-selection--single {
    border: 1px solid var(--#{$variable-prefix}border-color);
    transition: $form-select-transition;
}
.select2-automatic-tokenizer +.select2-container{
    display: inline;
}

.select2-dropdown {
    background: var(--bs-gray-900);
    border-color: var(--bs-border-color);
}

.select2-container--classic .select2-dropdown,
.select2-container--classic .select2-selection--single {
    background: var(--bs-gray-900);
}

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--single {
    background: var(--bs-gray-900);
}

.select2-container--classic .select2-selection--single .select2-selection__arrow {
    background-color: var(--bs-body-bg);
    border-color: var(--bs-border-color);
}

.select2-container--default.select2-container--disabled .select2-selection--single,
.select2-container--default.select2-container--disabled .select2-selection--multiple{
    background-color: var(--bs-gray-200);
}

